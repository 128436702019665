<template>
  <ul class="pagination">
    <li
      :class="[
        'page-item',
        { disabled: pagination.first == 0 || options.loadAll }
      ]"
    >
      <a href="#" @click.prevent.stop="$emit('page', 0)">First</a>
    </li>
    <li
      :class="[
        'page-item',
        { disabled: pagination.first == 0 || options.loadAll }
      ]"
    >
      <a
        href="#"
        tabindex="-1"
        @click.prevent.stop="$emit('page', pagination.first - pagination.size)"
        >Previous</a
      >
    </li>

    <li class="page-item">
      <span v-if="pagination.total > 0"
        >Showing {{ pagination.first + 1 }} - {{ pagination.last }} of
        {{ pagination.total }}</span
      >
      <span v-else>0 Results</span>
    </li>
    <li
      :class="[
        'page-item',
        {
          disabled:
            pagination.first + pagination.size >= pagination.total ||
            options.loadAll
        }
      ]"
    >
      <a
        href="#"
        @click.prevent.stop="$emit('page', pagination.first + pagination.size)"
        >Next</a
      >
    </li>
    <li
      :class="[
        'page-item',
        {
          disabled:
            pagination.first + pagination.size >= pagination.total ||
            options.loadAll
        }
      ]"
    >
      <a
        href="#"
        @click.prevent.stop="$emit('page', pagination.total - pagination.size)"
        >Last</a
      >
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default() {
        return {
          loadAll: false
        };
      }
    },
    pagination: {
      type: Object,
      default() {
        return {
          first: 0,
          last: 250,
          size: 250,
          total: 0
        };
      }
    }
  }
};
</script>
