var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"pagination"},[_c('li',{class:[
      'page-item',
      { disabled: _vm.pagination.first == 0 || _vm.options.loadAll }
    ]},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('page', 0)}}},[_vm._v("First")])]),_c('li',{class:[
      'page-item',
      { disabled: _vm.pagination.first == 0 || _vm.options.loadAll }
    ]},[_c('a',{attrs:{"href":"#","tabindex":"-1"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('page', _vm.pagination.first - _vm.pagination.size)}}},[_vm._v("Previous")])]),_c('li',{staticClass:"page-item"},[(_vm.pagination.total > 0)?_c('span',[_vm._v("Showing "+_vm._s(_vm.pagination.first + 1)+" - "+_vm._s(_vm.pagination.last)+" of "+_vm._s(_vm.pagination.total))]):_c('span',[_vm._v("0 Results")])]),_c('li',{class:[
      'page-item',
      {
        disabled:
          _vm.pagination.first + _vm.pagination.size >= _vm.pagination.total ||
          _vm.options.loadAll
      }
    ]},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('page', _vm.pagination.first + _vm.pagination.size)}}},[_vm._v("Next")])]),_c('li',{class:[
      'page-item',
      {
        disabled:
          _vm.pagination.first + _vm.pagination.size >= _vm.pagination.total ||
          _vm.options.loadAll
      }
    ]},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('page', _vm.pagination.total - _vm.pagination.size)}}},[_vm._v("Last")])])])}
var staticRenderFns = []

export { render, staticRenderFns }