<template>
  <div class="options">
    <div>
      <div class="form-horizontal columns">
        <div class="form-group column col-5">
          <div class="col-5 col-sm-12">
            <label class="form-label" for="fandom-filter">
              Fandom Name
            </label>
          </div>
          <div class="col-7 col-sm-12">
            <input
              class="form-input"
              type="text"
              id="fandom-filter"
              placeholder="Fandom name"
              v-model="options.filter.term"
            />
          </div>
        </div>

        <div class="form-group column col-5">
          <div class="col-4 col-sm-12">
            <label class="form-label" for="category-filter">Category</label>
          </div>
          <div class="col-8 col-sm-12">
            <select
              id="category-filter"
              class="form-select"
              v-model="options.filter.category"
            >
              <option value>All Categories</option>
              <option v-for="category in categories" :key="category">
                {{ category }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label
          class="form-checkbox form-inline"
          v-if="unlock && !minichallenge"
        >
          <input type="checkbox" v-model="options.onlyPrompts" />
          <i class="form-icon"></i> Only fandoms with prompts
        </label>
        <label v-if="hasLetters || isModIn" class="form-checkbox form-inline">
          <input type="checkbox" v-model="options.onlyBookmarks" />
          <i class="form-icon"></i> Only bookmarked fandoms
        </label>

        <label
          v-if="(hasLetters || isModIn) && !minichallenge"
          class="form-checkbox form-inline"
        >
          <input type="checkbox" v-model="options.onlyLetters" />
          <i class="form-icon"></i> Only fandoms with letters
          <span
            class="tooltip c-hand"
            data-tooltip="Letters submitted by users via 
the app or a letters post. This does
NOT include all letters in signups 
- see requests for those. 
These fandoms MAY include 
pinchhitter requests with no 
official signup or letters that
users forgot to put in their signup."
          >
            <sup>
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </sup>
          </span>
        </label>

        <!-- <div class="option" v-if="unlock">
          <input type="checkbox" id="ph" v-model="options.onlyPHs" />
          <label for="ph">Only fandoms with pinch hitters</label>
        </div> -->
      </div>

      <div class="form-group" v-if="!minichallenge">
        <label class="form-checkbox form-inline">
          <input type="checkbox" id="load-all" v-model="options.loadAll" />
          <i class="form-icon"></i> Load everything!
          <span
            class="warn tooltip"
            data-tooltip="Load all fandom data on one page. 
This may be hard on your browser."
          >
            <sup>
              <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
            </sup>
          </span>
        </label>
        <label v-if="hasLetters" class="form-checkbox form-inline">
          <input type="checkbox" v-model="options.hideCharacters" />
          <i class="form-icon"></i> Hide Nominated Characters column
        </label>

        <label v-if="hasLetters" class="form-checkbox form-inline">
          <input type="checkbox" v-model="options.hideLetters" />
          <i class="form-icon"></i> Hide Letters column
        </label>

        <label v-if="hasLetters" class="form-checkbox form-inline">
          <input type="checkbox" v-model="options.destyle" />
          <i class="form-icon"></i> Mobile letter URLs
          <span
            class="tooltip c-hand"
            data-tooltip="Use the mobile/plain theme
for letters hosted on 
DW/LJ/Tumblr/Google Docs, 
if possible. Good if you want 
to avoid custom themes."
          >
            <sup>
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </sup>
          </span>
        </label>

        <div class="form-group" v-if="unlock">
          <label v-if="unlock">
            Prompt Display Style
            <span
              class="tooltip c-hand"
              data-tooltip="Whether to display requested
characters and optional details in 
split or combined form when viewing 
requests."
            >
              <sup>
                <i class="fa fa-question-circle" aria-hidden="true"></i> &nbsp;
              </sup>
            </span>
            <label class="form-radio form-inline">
              <input type="radio" v-model="options.tableStyle" value="split" />
              <i class="form-icon"></i>Split
            </label>
            <label class="form-radio form-inline">
              <input
                type="radio"
                v-model="options.tableStyle"
                value="combined"
              />
              <i class="form-icon"></i>Combined
            </label>
          </label>

          <label class="form-checkbox form-inline" v-if="unlock">
            <input type="checkbox" v-model="options.useToggles" />
            <i class="form-icon"></i> Toggles for optional details
            <span
              class="tooltip c-hand"
              data-tooltip="Whether to use a toggle or display
the full text for optional details in prompts"
            >
              <sup>
                <i class="fa fa-question-circle" aria-hidden="true"></i>
              </sup>
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash-es";

export default {
  props: ["minichallenge"],
  computed: {
    ...mapGetters(["unlock", "hasLetters", "categories", "loadAll", "isModIn"])
  },
  watch: {
    options: {
      handler(val, oldVal) {
        if (val.filter.term.length && val.filter.term === oldVal.filter.term) {
          debounce(() => {
            this.$store.commit("setOptions", val);
          }, 500)();
        } else {
          this.$store.commit("setOptions", val);
        }
      },
      deep: true
    }
  },
  beforeMount() {
    if (this.$store.state.options) {
      this.options = this.$store.state.options;
    }
  },
  data() {
    return {
      showMsg: false,
      options: {
        filter: {
          category: "",
          term: ""
        },
        onlyLetters: false,
        onlyBookmarks: false,
        onlyPrompts: false,
        onlyPHs: false,
        destyle: false,
        loadAll: false,
        useToggles: false,
        tableStyle: "split",
        hideCharacters: false,
        hideLetters: false
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.warn {
  color: #e4a95c;
  padding-left: 2px;
}
</style>
